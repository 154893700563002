<template>
    <div id="pageNotFound">
        <PageTitle/>
        <div>
            <p v-html="content">
            </p>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "PageNotFound",
    components: {
        PageTitle
    },
    data(){
        return{
            content: "Sorry, the page you're looking for Couldn't be located.<br>Please return to the homepage.",
        }
    },
    metaInfo: {
        title: 'Page Not Found',
        titleTemplate: '%s by Meredith Schlosser',
        meta: [{
            name: 'description', 
            content: "Meredith Schlosser"
        }]
    },
}
</script>

<style lang="scss" scoped>
#pageNotFound{
    text-align: center;
}
</style>