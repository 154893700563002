<template>
<div class="articleheader"  style="background-image: url(/images/header/header3.jpg)">
    <div id="logo">
        <router-link to="/" aria-label="Go to Home Page">
            <img class="hero-image" src="/images/logo-meredith-bhhs.svg" alt="Meredith Schlosser Beverly Hills">
        </router-link>
        <div class="homepagelinks">
            <ul>
                <li>
                    <router-link to="/" alt="Home" class="btn btnlight" aria-label="Go to Home Page">
                        Home
                    </router-link>
                </li>
                <li>
                    <div class="dropdown">
                        <a class="btn btnlight dropbtn" tabindex="0" href="#" @click.prevent="showDropdownContent = !showDropdownContent" @mouseout="showDropdownContent = false" >About</a>
                        <div class="dropdown-content" @mouseout="showDropdownContent = false" v-bind:class="{show: showDropdownContent}">
                            <router-link to="/about" alt="About" aria-label="Go to About Page">
                                About Meredith Schlosser
                            </router-link>
                            <router-link to="/team" alt="Team" aria-label="Go to Team Page">
                                Meredith Schlosser Team
                            </router-link>
                            <a href="https://www.bhhscaproperties.com" target="blank" alt="BHHS" aria-label="Go to BHHS properties.">BHHS</a>
                        </div>
                    </div>
                </li>
                <li>
                    <router-link to="/listings" alt="Listings" class="btn btnlight" aria-label="Go to Listings Page">
                        Listings
                    </router-link>
                </li>
                <li>
                    <router-link to="/search?boardId=306&boundaryWKT=POLYGON%28%28-118.44546740238144%2034.01052486648203%2C-118.37050859761953%2034.01052486648203%2C-118.37050859761953%2033.948745909378005%2C-118.44546740238144%2033.948745909378005%2C-118.44546740238144%2034.01052486648203%29%29&propertyType=SFR,CND&status=active&sort=priceDesc" alt="search" class="btn btnlight" aria-label="Go to Search Page">
                        Search
                    </router-link>
                </li>
                <li>
                    <router-link to="/sold" alt="Sold" class="btn btnlight" aria-label="Go to Sold Properties Page">
                        Sold
                    </router-link>
                </li>
                <li>
                    <router-link to="/exclusive-guide" alt="Playa Vista Guide" class="btn btnlight" aria-label="Go to Playa Vista Guide Page">
                        Playa Vista Guide
                    </router-link>
                </li>
                <li>
                    <router-link to="/testimonials" alt="Testimonials" class="btn btnlight" aria-label="Go to Testimonials Page">
                        Testimonials
                    </router-link>
                </li>
                <li>
                    <router-link to="/press" alt="Press" class="btn btnlight" aria-label="Go to Press Page">
                        Press
                    </router-link>
                </li>
                <li>
                    <router-link to="/contact" alt="Contact" class="btn btnlight" aria-label="Go to Contact Page">
                        Contact
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:"PageTitle",
    data(){
        return {
            showDropdownContent: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.show{
    display: block !important;
}
</style>
